.redactor-box-grey.redactor-styles-on {
	border: 1px solid #ced4da;
	background-color: #f6f6f6;

	-webkit-border-radius: 0.25rem;
	-moz-border-radius: 0.25rem;
	border-radius: 0.25rem;

	-webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	-moz-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	-ms-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	-o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

	&.no-border {
		border: none !important;
	}

	.redactor-placeholder:before {
		color: $disabled;
		font-weight: 600;
	}

	&.redactor-focus{
		border-color: $primary !important;
		outline: 0;
		-webkit-box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
		-moz-box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
		box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
	}
}

.redactor-in{
    overflow: visible;
}
