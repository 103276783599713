.tag {
	padding: 2px 10px 2px 8px;
	margin: 5px;

	display: inline-flex;

	border: 1px solid white;

	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	flex: 1 1 auto;

	font-weight: 600;

	-webkit-transition: background 0.5s;
	-moz-transition: background 0.5s;
	-ms-transition: background 0.5s;
	-o-transition: background 0.5s;
	transition: background 0.5s;

	background-image: linear-gradient(to right, $primary 0%, $orange 51%, lighten($primary, 10%) 100%);
	background-size: 200% auto;
	color: white;

	&:hover {
		color: white;
		text-decoration: none;
		background-position: right center;
		border: 1px solid white;
	}

	&--close {
		cursor: pointer;
		padding-left: 15px;
		float: right;
	}
}