.login-page {
	&--wrapper {
		position: relative;

		@media screen and (min-width: 768px) {
			min-height: 100vh;
			height: 100%;
		}

		&-left {
			display: flex;
			flex-direction: column;
			justify-content: center;

			background-color: $secondary;
			background-image: url(/svg/feedo-bkgd.svg);
			background-repeat: no-repeat, repeat;
			background-position: calc(50% - 75px);
			background-attachment: fixed;

			height: 100vh;


			@media screen and (max-width: 767px) {
				display: none;
			}

			&-messages {
				margin: auto;
				color: white;

				max-width: 620px;

				&-ad {
					margin: 2.5em auto;
					display: inline-flex;

					&-image {
						margin: auto;
					}

					&-title {
						color: #fefefe;
						margin: auto auto auto 1em;
						font-weight: 300;
					}
				}
			}
		}

		&-right {
			background-color: white;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&-form {
				position: relative;
				margin: auto;

				max-width: 360px;
				max-height: 600px;

				@media screen and (max-width: 767px) {
					max-height: none;
					max-width: none;
				}
			}

			&-footer {
				width: 100%;
				text-align: center;

                @media screen and(min-width: 768px){
                    margin-top: 7em;
                }
			}
		}
	}
}
