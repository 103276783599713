.progress-bar {
	background-color: #f0f0f0;

	width: 100%;
	height: 8px;

	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;

	&.dark {
		background-color: #d6d4d4;
	}

	&--progress {
		background-color:  $primary;
		height: 8px;

		max-width: 100% !important;

		-webkit-border-radius: 30px;
		-moz-border-radius: 30px;
		border-radius: 30px;
	}

	&--transition {
		transition: width 1s;
	}
}