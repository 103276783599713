.answer-page {
	&--banner {
		min-height: 400px;

		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
		justify-content: space-between;

		@media screen and (max-width: 767px){
			min-height: 325px;
		}

		.container {
			z-index: 100;
		}

		&-bg {
			position: absolute;
			display: block;
			width: 100%;
			text-align: center;
			left: 0;
			z-index: 0;

			&-img {
				display: block;
				margin-top: 3em;
				margin-left: auto;
				margin-right: auto;

				@media screen and (max-width: 767px) {
					margin-top: 6em;
					max-width: 280px;
				}
			}
		}
	}

	&--header-group {
		background-color: white;
		background-image: linear-gradient(#ffffff 0%, #f6f6f6 90%);
	}

	&--campaign-title{
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		min-height: 43px;
		max-height: 45px;
	}

	&--label {
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;

		display: inline-block;
		padding: 0.2rem 35px;

		color: $secondary;
		background-color: white;

		font-weight: 700;
		font-size: 1rem;

		&-card {
			-webkit-border-radius: 13px;
			-moz-border-radius: 13px;
			border-radius: 13px;

			font-size: .9rem;

			padding: 0.2rem .975rem;

			background-color: #e7e6e6;
		}

		@media screen and (max-width: 767px) {
			padding: 0.375rem 30px;
		}
	}

	&--cards {
		&-wrapper {
			display: inline-flex;
			flex: 1;
			flex-wrap: wrap;

			width: 100%;

			margin-left: -15px;
			margin-right: -15px;

			@media screen and (max-width: 767px) {
				justify-content: center;
			}
			@media screen and (max-width: 991px) {
				margin-left: -5px;
				margin-right: -5px;
			}
		}
	}

	&--card {
		width: 350px;
		min-height: 280px;

		background-color: white;

		margin: 15px;

		padding: 1em 1.5em;

		-webkit-box-shadow: 0 5px 15px 3px #e7e6e6;
		-moz-box-shadow: 0 5px 15px 3px #e7e6e6;
		box-shadow: 0 5px 15px 3px #e7e6e6;

		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;

		@media screen and (max-width: 767px){
			width: 100%;
		}
		@media screen and (max-width: 991px) {
			margin: 5px;
		}

		&-header {
			width: 100%;
			min-height: 80px;

			&-title {
				font-size: 1.5rem;
			}

			&-logo {
				margin-bottom: 10px;
			}

			&-live span {
				color: $primary;

				&.dot {
					height: 10px;
					width: 10px;
					background-color: $primary;

					-webkit-border-radius: 50%;
					-moz-border-radius: 50%;
					border-radius: 50%;

					display: inline-block;

					margin-right: 5px;
				}
			}
		}

		&-bar {
			margin-bottom: 20px;
		}

		&-footer {
			width: 100%;

			display: inline-flex;
			justify-content: space-around;
			justify-content: space-between;
		}
	}

	&--questionnaire-page {
		max-width: 800px;

		@media screen and (max-width: 767px){
			max-width: 100%;
		}

		&-card {
			background-color: white;

			-webkit-box-shadow: 0 5px 15px 3px #e7e6e6;
			-moz-box-shadow: 0 5px 15px 3px #e7e6e6;
			box-shadow: 0 5px 15px 3px #e7e6e6;


			-webkit-border-radius: 10px;
			-moz-border-radius: 10px;
			border-radius: 10px;

			padding: 10px 15px;
			margin-bottom: 1em;

			@media screen and (min-width: 480px){
				min-height: calc(45vh - 80px);
			}

			&:not(.no-min-height){
				min-height: 300px;
			}

			&-progress {
				strong {
					font-size: 1.75rem;
					font-weight: bold;

					padding: 5px;
					border-bottom: 2px solid $primary;
				}

				span {
					font-size: 1.25rem;
					font-weight: bold;
					color: #b1b2c1;
				}
			}

			&-question {
				//min-height: 90px;

				@media screen and (min-width: 480px){
					min-height: 120px;
				}
			}
		}

		&-drag-area {
			position: relative;
			height: 220px;
			max-width: 250px;

			margin-left: auto;
			margin-right: auto;

			overflow: hidden;
			padding-top: 30px;

			user-select: none; /* supported by Chrome and Opera */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */

			&-selected {
				position: absolute;
				width: 100%;
				top: 70px;
				height: 80px;
				border-top: 2px solid #e7e6e6;
				border-bottom: 2px solid #e7e6e6;
			}

			&-list {
				position: relative;
				transition: all .3s ease 0s;
				&-item {
					transition: font-size .3s;
				}

				&-item.active {
					font-size: 4rem;
				}
			}

			&-overflow {
				position: absolute;
				top: 0;
				width: 100%;
				height: 220px;
				background: rgba(0,0,0,0);
				background-image: linear-gradient(#ffffff 10%, rgba(255,255,255,0) 50%, #ffffff 90%);
			}
		}

		&--footer {
			left: 0;
            margin-top: 25px;

			width: 100%;
			min-height: 60px;

			display: flex;

			z-index: 1000;
            border-radius: 10px;

            .na-answer{
                color: $disabled;

                &.active {
                    background: $primary;
                    span {
                        color: #ffffff !important;
                    }
                }
            }

            @media screen and (max-height: 760px){
                position: fixed;
                bottom: 0;
                margin-top: 0;
                border-radius: 0;
            }

			&:not(.no-bg) {
				background-color: white;

				-webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
				-moz-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
				box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);


			}
		}
	}
}
