.redactor-box {

	&.redactor-styles-on {
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;

		width: 100%;
		height: 100%;
		min-height: 400px;
		resize: none;
		margin-bottom: 1em;

		padding: 0.5rem 1rem;
		border: 1px solid #acacac;

		&.redactor-focus {
			outline: none;
			border: 1px solid #797979 !important;
		}
	}
}
.redactor-air a:hover, .redactor-toolbar a:hover {
	background: $primary;
}
.redactor-component {
	background: $primary !important;
	&-active{
		background: $secondary !important;
	}
}
.redactor-variables-list span {
	background: $primary !important;
	&.redactor-variables-item-selected{
		background: $secondary !important;
	}
}
.redactor-styles {
	color: $disabled;
	font-weight: 500;
}