.toast-message {
	color: $secondary !important;
	font-weight: 600;
}

#toast-container > div {
	background-color: white;
	opacity: 1;
	margin: 1em;
	border-radius: 10px;


	-webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, .2);
	-moz-box-shadow: 0 0 9px 0 rgba(0, 0, 0, .2);
	box-shadow: 0 0 9px 0 rgba(0, 0, 0, .2);

	&:hover {
		-webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, .4);
		-moz-box-shadow: 0 0 9px 0 rgba(0, 0, 0, .4);
		box-shadow: 0 0 9px 0 rgba(0, 0, 0, .4);
	}
}

.toast-title {
	color: $primary !important;
}



#toast-container > .toast-error {
	background-image: url('/svg/toastr_error.svg') !important;
}

#toast-container > .toast-warning {
	background-image: url('/svg/toastr_warning.svg') !important;
}
#toast-container > .toast-success, #toast-container > .toast-info {
	background-image: url('/svg/toastr_success.svg') !important;
}