.campaign-page {
	&--nav {
		width: 100%;

		display: inline-flex;

		flex: 1;
		flex-wrap: wrap;

		justify-content: space-between;

		&-step {
			cursor: pointer;

			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */


			width: 140px;
			height: 135px;

			-webkit-border-radius: 10px;
			-moz-border-radius: 10px;
			border-radius: 10px;

			-webkit-box-shadow: 0 0 15px 3px #e7e6e6;
			-moz-box-shadow: 0 0 15px 3px #e7e6e6;
			box-shadow: 0 0 15px 3px #e7e6e6;

			background-color: white;

			&:hover {
				background-color: lightgray;
				background-image: linear-gradient(#f6f6f6 0%, #ffffff 100%);
			}

			&.active {
				background-color: $primary;
				background-image: linear-gradient(#ff8a56 0%, #f0612e 100%);

				&:hover {
					background-image: linear-gradient(#e77653 0%, #f0612e 100%);
				}
			}

			&.active &-tick {
				background-color: white;
			}
			&.active &-title {
				color: white;
			}


			&-title {
				margin-bottom: 0;
				margin-top: 30px;
				padding-left: 20px;
				font-weight: bold;
				font-size: .9rem;
			}

			&-logo {
				padding-left: 20px;
				min-height: 50px;
			}

			&-tick {
				position: relative;
				margin: auto 5px auto auto;

				width: 16px;
				height: 16px;

				padding: 4px;

				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				border-radius: 50%;

				background-color: $primary;

				img {
					display: block;
				}

				&-wrapper {
					padding-top: 5px;
					min-height: 25px;
				}
			}
		}
	}

	&--header {
		background-color: #f6f6f6;
		background-image: linear-gradient(white 0%, #f6f6f6 100%);

		min-height: 350px;
	}

	&--cards {
		&-wrapper {
			display: inline-flex;
			flex: 1;
			flex-wrap: wrap;

			margin-left: -15px;
			margin-right: -15px;

			width: calc(100% + 30px);

			margin-top: 1.5em;

            &-col {
                flex: 1;
                position: relative;

                max-width: 100%;
                width: 100%;
                height: 275px;

                margin-bottom: 1.5em;

                @media screen and (min-width: 992px) {
                    max-width: 33.3%;
                }
            }

            &--empty {
                &-image {
                    max-width: 300px;
                    margin: auto;

                    &-description {
                        font-weight: 700;
                        margin-top: 2em;
                    }
                }
            }

			@media screen and (max-width: 767px) {
				justify-content: center;
			}
		}
	}

	&--card {

		background-color: white;

        margin: 0 15px 2em;

        padding: 1em 1.5em;

		-webkit-box-shadow: 0 5px 15px 3px #e7e6e6;
		-moz-box-shadow: 0 5px 15px 3px #e7e6e6;
		box-shadow: 0 5px 15px 3px #e7e6e6;

		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;


        &--launching {
            top: 0;
            position: absolute;
            width: 100%;
            margin: 0;
            padding: 0 15px 10px;
            height: 100%;

            &-container {
                background-color: rgba(255, 255, 255, .9);
                height: 100%;

                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                border-radius: 10px;

                display: flex;
                flex: auto;
                justify-content: center;
                align-items: center;
            }
        }

		&-delete {
			position: absolute;
			top: -10px;
			right: -10px;

			&-button {
				position: relative;
				background: none;
				outline: none;
				border: none;
				border-radius: 50%;

				&:focus {
					border: none;
					outline: none;
				}

				&:active {
					top: 1px;
					right: -1px;
				}
			}
		}

		&-header {
			width: 100%;
			min-height: 80px;

			&-title {
				font-size: 1.5rem;

				text-overflow: ellipsis;
				max-width: 100%;
				max-height: 29px;
				overflow: hidden;
				white-space: nowrap;
			}

			&-logo {
				margin-bottom: 10px;
			}

			&-live span {
				color: $primary;

				&.dot {
					height: 10px;
					width: 10px;
					background-color: $primary;

					-webkit-border-radius: 50%;
					-moz-border-radius: 50%;
					border-radius: 50%;

					display: inline-block;

					margin-right: 5px;
				}
			}
		}

		&-bar {
			margin-bottom: 20px;
			min-height: 39px;
		}

		&-footer {
			width: 100%;

			display: inline-flex;
			justify-content: space-around;
			justify-content: space-between;
		}
	}

	&--side-card {
		background-color: #f6f6f6;
		padding: 1em;

		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;

		margin-bottom: 20px;

		&-label {
			display: inline-block;
			padding: 5px 10px;

			-webkit-border-radius: 20px;
			-moz-border-radius: 20px;
			border-radius: 20px;

			width: auto;

			text-align: center;

			background-color: white;

			font-size: .9rem;
			font-weight: bold;

			white-space: nowrap;

			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 100%;
		}
	}

	&--table {

		&-header {
			margin-top: 1em;
		}
	}
}
