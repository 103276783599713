.settings-brand {
	&--logo{
		&-input {
			width: 100%;
			height: auto;

			border: 1px solid #bdbdbd;

			-webkit-border-radius: 10px;
			-moz-border-radius: 10px;
			border-radius: 10px;
		}
		&-button{
			padding: 8px 0;
			display: block;

			margin-top: 1em;

			text-align: center;

			width: 100%;

			border: 1px solid black;

			-webkit-border-radius: 10px;
			-moz-border-radius: 10px;
			border-radius: 10px;
		}
	}
}