.feedo-dropdown {
	position: absolute;
	margin-top: 5px;
	border: 1px solid $disabled;

	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;

	background-color: white;
	padding: 5px 20px 5px 10px;

	font-size: 1.2rem;
    z-index: 999;
}
