.dot {
	background-color: $secondary;

	display: inline-block;

	margin-top: auto;
	margin-bottom: auto;

	width: 23px;
	height: 23px;

	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}