.questionnaire_edit_table {
	&--header {
		color: $disabled;

		:first-child {
			padding-left: 0;
		}
	}

	&--body {
		:first-child {
			padding-top: .5em;
		}

		&-row {
			position: relative;
			border-top: 1px solid #e7e7e7;
			z-index: 1;

			:first-child {
				padding-left: 0;
			}

			&:not(.questionnaire_edit_table--body-row-header):not(.no-hover):hover {
				cursor: pointer;
				background-color: #f6f6f6;
				border-top: 1px solid #f6f6f6;
				z-index: 10;

				-webkit-box-shadow: 0 4px 9px 0 #e7e6e6;
				-moz-box-shadow: 0 4px 9px 0 #e7e6e6;
				box-shadow: 0 4px 9px 0 #e7e6e6;
			}
		}
	}

	&--cell {
		padding-top: 5px;
		padding-bottom: 5px;

		&-bold {
			padding-left: 0;
			font-weight: bold;
		}
	}


	&--question-select {
		//max-height: 600px;
		//overflow-y: auto;

		&-row {
			margin-top: .5em;
			margin-bottom: .5em;

			padding: 10px 15px 10px;

			width: 100%;

			display: -webkit-inline-box;
			display: -ms-inline-flexbox;
			display: inline-flex;

			background-color: #f6f6f6;
			border-top: 1px solid #f6f6f6;
			z-index: 10;

			-webkit-box-shadow: 0 4px 9px 0 #e7e6e6;
			-moz-box-shadow: 0 4px 9px 0 #e7e6e6;
			box-shadow: 0 4px 9px 0 #e7e6e6;

			&:hover {
				text-decoration: none !important;
			}


			&-question {
				width: 90%;
				margin: auto;
				text-align: justify;
			}

			&-button {
				width: 10%;
				padding-left: 20px;
				margin: auto;
				text-align: center;
			}
		}
	}
}