.btn {
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;

	font-weight: 700;
	font-size: 1rem;

	padding-left: 30px;
	padding-right: 30px;

	position: relative;


	&.btn-answer {
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;

		border: none;
		padding-left: 15px;
		padding-right: 15px;
		margin-left: 5px;
		margin-right: 5px;

		min-height: 45px;

		background-color: #efefef;

		color: $primary;

        &.disabled {
            opacity: 0;
        }

		&:focus {
			box-shadow: none;
		}
		&:not(.disabled):active {
			opacity: .5;
		}
	}

	&.btn-yes_no{
		margin-left: .25em;
		margin-right: .25em;

		padding-left: 10px;
		padding-right: 25px;

		width: 120px;

		max-height: 35px;

		display: flex;
		justify-content: space-between;
		align-items: center;

		border: none;
		outline: none;

		color: white;

		&:focus, &:active, &:active:focus {
			box-shadow: none !important;
		}

		&:active {
			color: white !important;
			span {
				color: white !important;
			}
		}

		span {
			font-weight: 800 !important;
			color: white;
		}

		&:not(.active){
			background-color: #e6e6e6;
			color: #7d7d8b;

			span {
				color: #7d7d8b;
			}
		}

		&.active {
			background-color: $primary !important;
		}
	}

	.btn-img {
		position: absolute;
		top: 30%;
		left: 10px;
	}

	&.btn-lg{
		padding: 0.375rem 90px;

		@media screen and (max-width: 767px) {
			padding: 0.375rem 30px;
		}
	}

	&.btn-outline {
		background: none;

		border: 1px solid $secondary;
		color: $secondary;

		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;

		&-primary {
			background-color: white;
			outline: white;
			border: none;

			&:hover {
				background-color: $primary;
			}
		}

		&.btn-lg {
			min-width: 260px;
		}

		&.rounded {
			-webkit-border-radius: 20px !important;
			-moz-border-radius: 20px !important;
			border-radius: 20px !important;
		}

		padding-left: 40px;
		padding-right: 40px;

		&:hover {
			background-color: #f6f6f6;
		}

		&:focus {
			-webkit-box-shadow: 0 0 0 0.2rem rgba($secondary, .25);
			box-shadow: 0 0 0 0.2rem rgba($secondary, .25);
		}
	}

	&.btn-bright {
		color: $secondary;
		background-color: white;

		&:hover {
			background-color: #e7e6e6;
		}
	}

	&.btn-back {
		color: #7d7d8b;
		background-color: #e7e6e6;

		&:hover {
			background-color: darken(#e7e6e6, 5%);
		}
	}

	&.btn-square {
		width: 100%;

		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		border-radius: 20px;

		padding-top: 15px;
		padding-bottom: 15px;

		border: 1px solid #e7e6e6;

		-webkit-box-shadow: 0 5px 15px 3px #e7e6e6;
		-moz-box-shadow: 0 5px 15px 3px #e7e6e6;
		box-shadow: 0 5px 15px 3px #e7e6e6;

		max-width: 300px;

		&:disabled {
			&:hover {
				background-color: #f6f6f6;
			}
		}
	}

	&-dropdown {
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;

		border: 2px solid #e7e6e6;

		background: none;

		padding: 8px;
		max-height: 32px;
		max-width: 32px;

		align-items: center;
		display: flex;

		&:focus {
			-webkit-box-shadow: 0 0 3px 0 $disabled;
			-moz-box-shadow: 0 0 3px 0 $disabled;
			box-shadow: 0 0 3px 0 $disabled;
		}
	}

	&.rounded-circle {
		padding: 10px 20px;
	}

	&-jobs {
		color: white;
		background-color: $disabled;
		padding: 0.3em .8em;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;

		&:focus {
			box-shadow: 0 0 0 0.2rem rgba($disabled, 0.25);
		}

		&.active {
			background-color: $primary;

			&:focus {
				box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
			}
		}

		&:hover {
			background-color: $highlight;
		}
	}
}
