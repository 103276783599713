
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Toastr
@import '~toastr/toastr';
// Select 2
@import '~select2/src/scss/core';

// Base
@import 'base/typography';
@import 'base/inputs';
@import 'base/loaders';
@import 'base/buttons';
@import 'base/lists';
@import 'base/toastr';

// Layout
@import 'layout/navbar';
@import 'layout/page-navbar';

// Components
@import 'components/custom-checkbox';
@import 'components/custom-table';
@import 'components/search-input';
@import 'components/feedo-modal';
@import 'components/tag';
@import 'components/color-input';
@import 'components/redactor-style';
@import 'components/rating-cards';
@import 'components/toggle-switch';
@import 'components/campaign-card';
@import 'components/progress-bar';
@import 'components/calendar';
@import 'components/dot';
@import 'components/feedo-dropdown';
@import 'components/select2';
@import 'components/redactor';

// Modals - specific
@import 'modals/smiley-modal';

// Pages
@import 'pages/campaign-page';
@import 'pages/login-page';
@import 'pages/settings-brand';
@import 'pages/answer-page';
@import 'pages/reporting-page';
@import 'pages/pdf-insights-page';

// Tables
@import 'tables/questionnaire_edit_table';
@import 'tables/csv-table';

// Animations
@import 'animations/list-transation';