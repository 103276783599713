.color-input {
	&--button{
		position: absolute;

		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;

		top: 29px;
		right: 20px;
		width: 28px;
		height: 28px;
	}
}