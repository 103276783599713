.smiley-modal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-ms-flex-wrap: wrap;
	flex-wrap: wrap;

	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;

	&--card {
		border: 2px solid #e7e6e6;

		cursor: pointer;

		width: 84px;
		height: 84px;

		padding: 12px;

		margin: 10px;

		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;

		background-color: white;

		&-smile {
			display: block;
			margin: auto;
			width: 100%;
			height: 100%
		}

		&:hover {
			border: 2px solid $primary;
		}
	}
}