.list-transition {
	&-item {
		-webkit-transition: transform 1s;
		-moz-transition: transform 1s;
		-ms-transition: transform 1s;
		-o-transition: transform 1s;
		transition: transform 1s;
	}

	&-enter, &-leave-active {
		-webkit-transition: transform 0s;
		-moz-transition: transform 0s;
		-ms-transition: transform 0s;
		-o-transition: transform 0s;
		transition: transform 0s;
	}
}