.calendar {

	width: 300px;
	margin: auto;

	&--wrapper {
		background-color: #f6f6f6;
		height: 250px;

		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		border-radius: 15px;

		padding: 10px 20px;
	}

	&--header {
		display: flex;
		justify-content: space-around;
		justify-content: space-between;

		&-display {
			font-weight: 600;
			font-size: 1rem;

			margin: auto;
		}

		&-chevron {
			padding: 10px 5px;

			&:not(&.disabled){
				cursor: pointer;
			}
		}
	}

	&--weekdays {
		margin-top: 1em;

		display: flex;
		justify-content: space-around;
		justify-content: space-between;

		font-weight: 700;
		font-size: .9rem;

		&-day {
			width: 23px;
			margin: 2px 7px;
			text-align: center;

			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}

	&--body {
		display: flex;
		flex: 1;

		flex-wrap: wrap;
	}

	&--cell {
		width: 23px;
		margin: 2px 7px;
		text-align: center;

		cursor: pointer;

		&.disabled {
			cursor: default;
			color: $disabled;
		}

		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;

		&.today {
			color: white;
			background-color: lighten($secondary, 30%);
			background-color: rgba(lighten($secondary, 30%), .4);

			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;

			&:not(.disabled).active, &:not(.disabled):hover {
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				border-radius: 5px;
			}
		}

		&:not(.disabled):hover {
			background-color: lighten($primary, 10%);
		}

		&:not(.disabled).active {
			background-color: $primary;
		}

		&:not(.disabled).active, &:not(.disabled):hover {
			color: white;
		}
	}


	&--hours-wrapper {
		display: flex;
		margin: 20px 10px;
		justify-content: center;

		span {
			font-size: 1rem;
			font-weight: 500;
		}

		h5 {
			font-size: 1rem;
			font-weight: 500;
			margin: auto 10px;
		}

		select {
			border: none;
			background-color: #e7e6e6;
			background-image: linear-gradient(#f6f6f6 0%, #e7e6e6 100%);;
			margin: auto 10px;
			max-width: 70px;
			max-height: 30px;
			padding: 5px;
		}
	}

	&--footer {
		border-top: 3px solid $primary;
	}
}