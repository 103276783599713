.campaign-card {
	width: 100%;

	padding: 1em;

    &:hover {
        text-decoration: none;
    }

	&:not(.inactive):hover {

		.campaign-card--wrapper{
			background-color: $primary;
			background-image: linear-gradient(#ff8a56 0%, #f0612e 100%);

			z-index: 100;

			-webkit-box-shadow: 0 3px 14px 3px #cecece;
			-moz-box-shadow: 0 3px 14px 3px #cecece;
			box-shadow: 0 3px 14px 3px #cecece;
		}
		.campaign-card--description, .campaign-card--title {
			color: white;
		}
		.campaign-card--logo {
			display: none;
			&-alt {
				display: inline;
			}
		}
	}

    &.inactive {
        .campaign-card--title, .campaign-card--description {
            opacity: .4;
        }
    }

	&--wrapper {
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;

		padding: 2em 1em;

		background-color: #f6f6f6;

		text-align: center;

		transition: box-shadow 0.5s;

		min-height: 200px;
	}

	&--logo {
		max-width: 78px;
		max-height: 78px;
		display: inline;

		&-alt {
			max-width: 78px;
			max-height: 78px;
			display: none;
		}

		&-wrapper {
			min-height: 78px;
		}
	}

	&--title {
		margin-top: 1em;
		font-weight: bold;
		font-size: 1.1rem;
		color: $secondary;
	}

	&--description {
		max-width: 75%;
		margin: auto !important;
		text-align: center;
	}
}
