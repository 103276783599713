//Custom check box
.checkbox {
	height: 100%;
	width: 100%;
	display: flex;
}

.customer_checkbox {
	height: 23px;
	display: block;
	position: relative;
	padding-left: 27px;
	margin-top: auto;
	margin-bottom: auto;
	cursor: pointer;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.customer_checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 4px;
	left: 4px;
	height: 15px;
	width: 15px;
	background-color: #fafafa;
	border: 3px solid #fafafa;

	-webkit-box-shadow: 0 0 1px 0 #999;
	-moz-box-shadow: 0 0 1px 0 #999;
	box-shadow: 0 0 1px 0 #999;
}

/* If input is disabled */
.customer_checkbox input[disabled] ~ .checkmark {
	border: 1px solid #dadcd5;
}

/* On mouse-over, add a grey background color */
.customer_checkbox:hover input:not([disabled]) ~ .checkmark {
	//-moz-box-shadow:    2px 2px 1px #ccc;
	//-webkit-box-shadow: 2px 2px 1px #ccc;
	//box-shadow:         2px 2px 1px #ccc;

	border: 1px solid $disabled;
}

/* When the checkbox is checked, add a blue background */
.customer_checkbox input:checked ~ .checkmark {
	background-color: #fafafa;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */

.customer_checkbox input:checked ~ .checkmark:after {
	display: block;
	top: -1px;
	left: 1px;
	padding: 2px;
}

.customer_checkbox:hover input:checked ~ .checkmark:after {
	top: 1px;
	left: 3px;
 }

/* Style the checkmark/indicator */
.customer_checkbox .checkmark {
	&:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid $primary;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}


.just-tick {
	display: block;
	top: 3px;
	left: 5px;
	padding: 2px;
	width: 5px;
	height: 10px;
	border: solid $primary;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}