.rating-cards {

	&--wrapper {
		display: flex;
		width: 100%;
		justify-content: center;
		flex-wrap: wrap;

		&.numbers {
			margin: auto;
			max-width: 680px;

			justify-content: start;
		}
	}

	&--no-selection {
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-o-user-select: none;
		user-select: none;
	}

	&--tooltip {
		display: inline-block;
		margin: auto;
		position: relative;

		&-body {
			width: 100%;
			top: 0;
			position: absolute;
			text-align: center;
			height: 25px;
			overflow: hidden;

			&-text {
				color: white;
			}
		}
	}

	&--card {
		display: block;
		margin: 0 .25em auto .25em;
		text-align: center;

		cursor: pointer;

		&-text {
			width: 100%;
			padding-top: 20px;
			color: $disabled;
			font-size: 1.1rem;
		}

		&-icon {
			display: flex;
			width: 165px;
			height: 155px;

			-webkit-border-radius: 10px;
			-moz-border-radius: 10px;
			border-radius: 10px;

			border: 2px solid $disabled;

            padding: 20px 10px;

            @media screen and (max-width: 767px){
				width: 85px;
				height: 75px;
			}

			img {
				margin: auto;


				@media screen and (max-width: 767px){
					width: 50%;
				}
			}
		}

		&:hover {
			.rating-cards--card-text {
				color: $primary;
			}
		}

		&.active {
			.rating-cards--card-text {
				color: $primary;
			}
			.rating-cards--card-icon {
				border: 2px solid $primary;
			}
		}
	}

	&--numbers {
		&-tag {
			width: 48px;
			height: 48px;
			color: $disabled;

			padding: 5px;

			cursor: pointer;

			font-size: 1.4rem;
			font-weight: 700;

			text-align: center;

			margin: .25em;

			border: 2px solid $disabled;

			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;

			@media screen and (max-width: 767px) {
				width: 38px;
				height: 38px;
				padding: 5px;
				font-size: 1rem;
				margin-bottom: 15px;
			}

			&:hover {
				color: $primary;
                @media screen and (max-width: 767px) {
                    color: $disabled;
                }
			}

            &:active, &:focus {
                color: $disabled;
                outline: none;
            }

			&.active {
				color: $primary;
				border: 2px solid $primary;
			}
		}

		&-radio {

			position: relative;
			display: flex;
			align-items: center;
			min-width: 16.3%;
			margin-bottom: 2em;

			cursor: pointer;

			@media screen and (min-width: 430px) and (max-width: 530px){
				min-width: 24.3%;
				margin-bottom: 1em;
			}

			@media screen and (max-width: 429px){
				min-width: 33.3%;
				margin-bottom: .5em;
			}

			&:hover > &--wrapper {
				border: 2px solid $primary;
			}

			&--wrapper {
				width: 29px;
				height: 29px;
				color: $disabled;

				padding: 3px;

				font-size: 1.4rem;
				font-weight: 700;

				text-align: center;

				margin: .25em;

				border: 2px solid $disabled;

				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				border-radius: 50%;

				&.active {
					border: 2px solid $primary;
				}

				&-dot {
					position: fixed;
					width: 19px;
					height: 19px;

					background-color: $primary;

					-webkit-border-radius: 50%;
					-moz-border-radius: 50%;
					border-radius: 50%;
				}
			}
		}
	}

	&--list {
		padding: 0;
		max-width: 300px;
		margin: auto;

		&-option {
			list-style: none;

			cursor: pointer;

			padding: 10px 20px;
			margin: 10px auto;

			font-weight: 600;

			background-color: #f6f6f6;
			color: $disabled;

			-webkit-border-radius: 10px;
			-moz-border-radius: 10px;
			border-radius: 10px;

			&:hover, &.active, &.active:hover {
				background-color: rgba($primary, .20);
				color: $primary;
			}
		}
	}

	&--saved-card {
		margin: 2em .25em auto .25em;

		width: 190px;
		height: 190px;

		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;

		-webkit-box-shadow: 0 0 12px 1px #d4d3d3;
		-moz-box-shadow: 0 0 12px 1px #d4d3d3;
		box-shadow: 0 0 12px 1px #d4d3d3;

		&:not(.dont-hover):hover {
			-webkit-box-shadow: 0 0 12px 1px #acabab;
			-moz-box-shadow: 0 0 12px 1px #acabab;
			box-shadow: 0 0 12px 1px #acabab;

			cursor: pointer;
		}


		&.active {
			border: 2px solid $primary;
		}

		&-type {
			display: flex;
			width: 100%;
			height: 70%;

			img {
				display: block;
				margin: auto;
			}
		}
		&-footer {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;

			justify-content: space-around;
			justify-content: space-between;

			background-color: #f6f6f6;

			text-align: left;
			padding-left: 10px;
			padding-right: 10px;

			width: 100%;
			height: 30%;

			border-bottom-right-radius: 10px;
			border-bottom-left-radius: 10px;
		}
	}
}
