p, a, span {
	color: $secondary;
	font-weight: 600;
	font-size: .9rem;

	&.text-grey {
		color: $disabled;
	}
}

h1 {
	color: $secondary;
	font-weight: 900;

	b {
		font-weight: 900;
		color: $primary;
	}
}

h1, h2, h3, h4, h5, h6 {

	&.text-grey {
		color: $disabled;
	}
}

h2.center-limit, h3.center-limit, h4.center-limit {
	max-width: 360px;
	font-weight: 800;
	margin-left: auto !important;
	margin-right: auto !important;
	text-align: center;
}

*.cursor-pointer {
	cursor: pointer;
}