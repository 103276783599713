.select2 {
	&-container {
		font-family: $font-family-sans-serif;

		&--open .select2-dropdown--below,
		&--open .select2-dropdown--above {
			background: white;
		}

		&--open.select2.select2-container--flat,
		&--open.select2.select2-container--flat,
		&--focus.select2.select2-container--flat,
		&--focus.select2.select2-container--flat {
			box-shadow: 0 0 0 0.2rem rgba(240, 97, 46, 0.25);
		}

		&--flat {
			border: 1px solid #ced4da;
			background-color: white;

			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;

			padding: 0.3em;
			font-size: 0.9rem;

			span {
				color: #495057;
				font-weight: 500;
			}

			.select2-container--focus .select2-selection--multiple{
				border: 1px solid #ced4da;
			}
			.select2-results__option--highlighted[aria-selected]{
				background: $blue !important;
				color: #ffffff;
			}
			.select2-results__option[aria-selected=true]{
				//background: $blue;
				//color: #fff;
				//opacity: 0.8;
			}
			span.select2-search{
				input {
					background-color: #efefef;
					border: 1px solid #ced4da;
					padding: .4em 1em;
				}
			}

			.select2-selection__arrow {
				height: 26px;
				position: absolute;
				top: 1px;
				right: 1px;
				width: 20px;
				b {
					border-color: black transparent transparent transparent;
					top: 60%;
					border-style: solid;
					border-width: 6px 3px 0 3px;
					height: 0;
					left: 50%;
					margin-left: -1px;
					margin-top: -2px;
					position: absolute;
					width: 0;
				}
			}
		}

		&.select2-container--open{
			.select2-selection__arrow{
				b{
					transform: rotate(180deg);
					-webkit-transform: rotate(180deg);
					-moz-transform: rotate(180deg);
				}
			}
		}

		.select2-choice {
			border: 2px solid #ced4da;
			height: 36px;

			background-image: none;

			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}

		*:focus{
			outline: 0;
		}

		&.select2-drop-above .select2-choice {
			border-bottom-color: #dce4ec;
			border-radius:0;
		}

		//Disabled arrow rotation
		&.select2-container--open .select2-selection__arrow b {
			transform: none;
		}
	}

	&-results__options {
		max-height: 350px;
		overflow: auto;
	}
}
