.csv-table{
	background-color: white;
	font-size: .8rem;

	thead > tr {
		background-color: #d6d6d6;
		text-align: center;
	}

	tr > td, tr > th {
		border: 1px solid #9d9d9d;
		padding: 0;
	}

	tr > th {
		padding: 10px;
	}

	tr.error {
		background-color: #ffd0d0;
	}

	td.error {
		background-color: #ff3f3f;
	}


	&--wrapper {
		display: block;

		max-width: 95%;
		max-height: 350px;
		overflow: auto;

		-webkit-box-shadow: 0 2px 9px 3px #b1b1b1;
		-moz-box-shadow: 0 2px 9px 3px #b1b1b1;
		box-shadow: 0 2px 9px 3px #b1b1b1;
	}

	&--error {
		&-display {
			width: 100%;
			display: block;
			text-align: center;
		}
	}

	&--input {
		padding: 5px;
		background: none;

		&:focus {
			background: #efefef;
		}
	}
}