.reporting-page {
	&--summary-card {
		background-color: #f6f6f6;

		width: 100%;

		padding: 15px 15px 20px;

		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		border-radius: 15px;

		&-row {
			width: 100%;
		}

		&-result {
			font-weight: 600;
			font-size: 2.7rem;
			margin-bottom: -5px;
		}
	}

	&--section {
		padding-bottom: 2em;
		padding-top: 2em;

		//border-bottom: 1px solid rgba(0, 0, 0, .1);

		&-header {
			display: flex;
			justify-content: space-around;
			justify-content: space-between;

			&-title {
				font-weight: 800;
				font-size: 1.3rem;
			}

			&-status {
				.closed {
					.vertical {
						transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
						transform: rotate(-90deg);
					}
					.horizontal {
						transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
						transform: rotate(-90deg);
						opacity: 1;
					}
				}

				.circle-plus {
					height: 0;
					width: 4em;
					font-size: 1rem;
					opacity: 1;
				}

				.circle-plus .circle {
					position: relative;
					width: 2.55em;
					height: 2.5em;
					margin-left: auto;
					border-radius: 100%;
				}

				.circle-plus .circle .horizontal {
					position: absolute;
					background-color: $secondary;
					width: 15px;
					height: 3px;
					left: 50%;
					margin-left: -8px;
					top: 50%;
					margin-top: -9px;
				}
				.circle-plus .circle .vertical {
					position: absolute;
					background-color: $secondary;
					width: 3px;
					height: 15px;
					left: 50%;
					margin-left: -2px;
					top: 50%;
					margin-top: -15px;
				}
			}


			&[aria-expanded="true"] > &-status {
				opacity: 1;
				.vertical {
					transform: rotate(90deg);
				}
				.horizontal {
					transform: rotate(90deg);
					opacity: 0;
				}
			}

			&:hover {
				text-decoration: none;
				.circle-plus .circle {
					.horizontal, .vertical {
						background-color: $primary;
					}
				}
			}
		}

		&-card {
			background-color: #f0f0f0;
			padding: 2px;
			margin-bottom: 10px;

			&-header {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}