.search-input {
	font-size: 1.5rem;
	font-weight: 400;

	@media screen and (max-width: 767px){
		width: 100%;
		display: inline-flex;
		font-size: 1.1rem;

		&--input {
			width: 90%;
		}

		&--cancel{
			margin: auto;
		}
	}

	input {
		background-color: white;
		margin-top: 5px;

		&:focus {
			outline: none;
		}

		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			font-weight: 400;
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			font-weight: 400;
		}

		&::-ms-input-placeholder { /* Microsoft Edge */
			font-weight: 400;
		}
	}

	&.small {

		width: 250px;

		@media screen and (max-width: 767px){
			width: auto;
		}

		input {
			background-color: white;
			padding: 10px;
			width: 70%;
			font-size: .8rem;

			&:focus {
				outline: none;
			}

			&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
				font-weight: 400;
				font-size: .8rem;
			}

			&:-ms-input-placeholder { /* Internet Explorer 10-11 */
				font-weight: 400;
				font-size: .8rem;
			}

			&::-ms-input-placeholder { /* Microsoft Edge */
				font-weight: 400;
				font-size: .8rem;
			}
		}
	}
}