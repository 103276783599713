.loaders {
	&--standard {
		margin: auto;

		border: 5px solid $disabled;
		border-radius: 50%;
		border-top: 5px solid $primary;
		width: 55px;
		height: 55px;
		-webkit-animation: spin 2s linear infinite; /* Safari */
		animation: spin 2s linear infinite;

		&.override {
			position: absolute;
			left: calc(50% - 27px);
		}
	}

	/* Safari */
	@-webkit-keyframes spin {
		0% { -webkit-transform: rotate(0deg); }
		100% { -webkit-transform: rotate(360deg); }
	}

	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}

	&--large {
		margin: auto;

		border: 5px solid $disabled;
		border-radius: 50%;
		border-top: 5px solid $primary;
		width: 70px;
		height: 70px;
		-webkit-animation: spin 2s linear infinite; /* Safari */
		animation: spin 2s linear infinite;
	}

	/* Safari */
	@-webkit-keyframes spin {
		0% { -webkit-transform: rotate(0deg); }
		100% { -webkit-transform: rotate(360deg); }
	}

	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
}