.list-group {
    max-height: 800px;
    overflow-y: auto;

	&-item {
		z-index: 1;
		text-decoration: none;

		padding: 5px 0;
		margin-bottom: 10px;

		border: none;
		border-bottom: 1px solid #dfdfdf;

		&:last-child {
			border: none;
		}

		a {
			color: $disabled;
			width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: inline-flex;

			flex-wrap: nowrap;
			flex: 1;
			justify-content: space-between;

			span {
				color: $disabled;
			}

			&:hover {
				text-decoration: none;
				font-weight: 700;
				color: $secondary;

				span {
					text-decoration: none;
					font-weight: 700;
					color: $secondary;
				}
			}
		}
	}
}

.right-side-list {
	&--item {
		display: block;
		width: 100%;

		text-align: right;

		font-weight: 800;
		color: $secondary;
		padding: 5px;
		margin-top: 10px;

		&.disabled {
			color: #a3a3af;
		}

		&:hover {
			text-decoration: none;

			&.disabled {
				color: #a3a3af;
			}
		}

		&.group {
			display: flex;
			display: inline-flex;
			justify-content: flex-end;
		}
	}

	&--input {
		width: 100%;
		margin: auto;
		padding: 5px !important;
	}
}
