.custom-table {
	width: 100%;
	margin-bottom: 3em;

	thead {
		color: $disabled;
	}

	a {
		color: $primary;
	}

	tr {
		border-bottom: 1px solid #efefef;

		td, th {
			padding-top: 15px;
			padding-bottom: 10px;
		}
	}

	input:not(.no-stretch) {
		min-width: 130px;
	}

	@media screen and (max-width: 767px){
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;

		font-size: .8rem;

		-webkit-box-shadow: 0 0 2px 1px $disabled;
		-moz-box-shadow: 0 0 2px 1px $disabled;
		box-shadow: 0 0 2px 1px $disabled;
	}

	&--header {
		&-long {
			min-width: 500px;
			@media screen and (max-width: 991px) {
				min-width: 350px;
			}
			@media screen and (max-width: 767px) {
				min-width: auto;
			}
		}
	}
}