.feedo-modal {
	display: none;

	&.active {
		display: block;
	}

	position: fixed;

	background-color: $secondary; /* Fallback color */
	background-color: rgba($secondary,0.75); /* Black w/ opacity */

	top: 0;
	left: 0;

	text-align: center;

	z-index: 1000;

	width: 100%;
	height: 100%;

	&--card {
		position: relative;

		display: inline-block;

		text-align: left;

		top: 40%;
		transform: translateY(-40%);

		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;

		padding: 0;

		background-color: $body-bg;

		max-width: 80%;
		overflow-x: hidden;

		@media screen and (max-width: 767px){
			max-width: 90%;
		}

		&-header {
			padding: 10px;
			border-bottom: 2px solid #e7e6e6;

			&-title {
				h4 {
					font-weight: 700;
					margin-bottom: 3px;
					text-align: center;
				}
			}

			&-close {
				position: absolute;

				padding-top: 10px;

				top: 1px;
				right: 0;

				width: 48px;
				height: 48px;

				text-align: center;
			}
		}

		&-body {
			max-height: 65vh;
			background-color: #f6f6f6;

			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;

			overflow-y: auto;
		}
	}

	&--no-scroll {
		overflow: hidden;
	}
}