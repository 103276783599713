input, input.form-control {
	padding: 15px 20px;
	
	background-color: $input-bg;
	color: lighten($secondary, 20%);
	font-weight: 700;
	border: none;

	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;

	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: $disabled;
		font-weight: 600;
		opacity: 1; /* Firefox */
	}

	&::-webkit-input-placeholder {
		color: $disabled;
		font-weight: 600;
	}

	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: $disabled;
		font-weight: 600;
	}

	&::-ms-input-placeholder { /* Microsoft Edge */
		color: $disabled;
		font-weight: 600;
	}


	&:focus {
		color: lighten($secondary, 20%);
		background-color: $input-bg;
	}
}

input.digit {
	padding: 5px;
	max-width: 30px;
	max-height: 30px;
	text-align: center;
	border: 1px solid #cecece;
}