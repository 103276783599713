.pdf-insights-page {
	&--header {
		height: 210px;
		display: flex;
		justify-content: end;

		&-img {
            position: relative;
			display: block;
			width: 200px;
			height: 210px;

			img {
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;

                display: block;
				margin: auto !important;
				width: 100%;
				height: auto;
				max-height: 200px;
			}
		}
	}

	&--body {
		padding: 1em 3em;

		h1 {
			font-size: 2.7rem;
		}
		h2 {
			font-size: 2.4rem;
		}

		&-title {
			color: $secondary;
			font-weight: 700;
		}

		&.banner {
			background-color: $primary;
			padding-top: 2.5em;
			padding-bottom: 2.5em;
			height: 300px;
			position: relative;

			h1, h2, h3, h4, h5, h6 {
				color: white;
			}

			.title {
				display: -webkit-box;
				max-height: 150px;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.date {
				position: absolute;
				bottom: 20px;
                text-wrap: none;
                width: 600px;
			}
		}

		img {
			border: none;
		}
	}
}
