.page-navbar {
	width: 100%;
	margin-top: 1em;
	margin-bottom: 8px;

	&--link {
		font-weight: 600;
		font-size: .9rem;
		margin-right: 2em;
		margin-bottom: 0;
		padding: 0 20px 8px;
		color: $disabled;

		@media screen and (max-width: 767px) {
			font-size: .8rem;
			margin-right: 5px;
			padding: 5px 0 8px;
		}

		@media screen and (max-width: 400px) {
			font-size: .6rem;
		}


		&:hover, &:focus {
			color: $secondary;
			text-decoration: none;
		}

		&.active {
			color: $secondary;
			border-bottom: 3px solid $primary;
		}
	}
}