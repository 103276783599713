.navbar {
	padding: 15px 20px 15px 40px;

	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	//border-bottom: 1px solid #e4e4e4;
	-webkit-box-shadow: 0 0 1px 1px #e4e4e4;
	-moz-box-shadow: 0 0 1px 1px #e4e4e4;
	box-shadow: 0 0 1px 1px #e4e4e4;

	&.inner {
		padding: 0;
		justify-content: center;
		box-shadow: none;
	}

	&--ham {
		display: none;
		
		@media screen and (max-width: 880px){
			display: block;
		}
	}


	&--logo {
		-ms-order: 0;
		order: 0;

		@media screen and (max-width: 880px){
			-ms-order: 1;
			order: 1;
		}
	}

	&--toggles {
		display: flex;

		-ms-order: 1;
		order: 1;
		background-color: $primary;

		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;

		&-link {
			text-align: center;
			padding: 7px 10px;
			margin-top: 1px;
			margin-right: 1px;
			margin-bottom: 1px;
			color: white;

			display: inline-block;
			min-width: 105px;

			background-image: linear-gradient(to right, $primary 0%, $orange 100%);

			&:first-child {
				border-bottom-left-radius: 5px;
				border-top-left-radius: 5px;
				margin-left: 1px;
			}

			&:last-child {
				border-bottom-right-radius: 5px;
				border-top-right-radius: 5px;
			}

			&:hover {
				color: white;
				text-decoration: none;
			}

			&:not(.active) {
				color: $disabled;
				background-image: none;
				background-color: white;
			}
		}
	}

	&--links {

		-ms-order: 1;
		order: 1;

		@media screen and (max-width: 880px){
			display: none;

			-webkit-box-shadow: 0 0 1px 1px #e4e4e4;
			-moz-box-shadow: 0 0 1px 1px #e4e4e4;
			box-shadow: 0 0 1px 1px #e4e4e4;

			-ms-order: 0;
			order: 0;

			position: absolute;

			width: 100%;
			max-width: 180px;

			top: 50px;
			left: 0;

			z-index: 200;

			flex-wrap: wrap;
			background-color: white;

			&.shown {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
			}
		}

		&-link {
			padding: 8px 15px;
			margin: 5px;
			color: $secondary;

			border: 1px solid white;

			-webkit-border-radius: 10px;
			-moz-border-radius: 10px;
			border-radius: 10px;

			-webkit-transition: border 0.5s;
			-moz-transition: border 0.5s;
			-ms-transition: border 0.5s;
			-o-transition: border 0.5s;
			transition: border 0.5s;

			@media screen and (max-width: 880px){
				width: 100%;
			}



			&:focus{
				text-decoration: none;
			}
			&:hover {
				color: $secondary;
				text-decoration: none;
				outline: $primary;
				border: 1px solid $primary;
			}

			&.active {
				flex: 1 1 auto;

				-webkit-transition: background 0.5s;
				-moz-transition: background 0.5s;
				-ms-transition: background 0.5s;
				-o-transition: background 0.5s;
				transition: background 0.5s;

				background-image: linear-gradient(to right, $primary 0%, $orange 51%, lighten($primary, 10%) 100%);
				background-size: 200% auto;
				color: white;

				&:hover {
					color: white;
					text-decoration: none;
					background-position: right center;
					border: 1px solid white;
				}
			}
		}
	}

	&--profile {

		-ms-order: 2;
		order: 2;

		display: inline-flex;

		&-button {
			padding-right: 15px;
			display: flex;
			flex-wrap: nowrap;

			&:hover, &:focus {
				text-decoration: none;
			}

			* {
				margin-bottom: auto;
				margin-top: auto;
				padding-left: 10px;
			}
		}

		&-dropdown {
			position: absolute;

			z-index: 100;

			top: 55px;
			right: 25px;

			background-color: white;

			-webkit-box-shadow: 0 0 1px 1px #e4e4e4;
			-moz-box-shadow: 0 0 1px 1px #e4e4e4;
			box-shadow: 0 0 1px 1px #e4e4e4;

			&-list {
				padding: 0;
				list-style-type: none;

				&-item {
					margin: 10px auto;
					text-align: center;

					a {
						margin-top: 5px;
						padding: 10px 30px;
					}
				}
			}
		}
	}
}